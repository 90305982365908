<template>
  <EntitySingle :config="config" :entity.sync="entity" :loaded.sync="entityLoaded">
    <b-card v-if="entityLoaded">
      <b-tabs pills>
        <!-- Tab: Information -->
        <b-tab active>
          <template #title>
            <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Basic Information') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="infoFormFields"
            allow-autofocus
          />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="MapPinIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Contact') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="contactFormFields"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="ImageIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Logo') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="logoFormFields"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="DollarSignIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Billing Info') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="billingFormFields"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </EntitySingle>
</template>

<script>
import { BTab, BTabs, BCard } from 'bootstrap-vue'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import { translationUtils } from '@core/mixins/ui/translations'
import storeModule from '../SponsorStoreModule'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
    BTab,
    BTabs,
    BCard,
  },
  mixins: [translationUtils],
  data() {
    return {
      entity: {
        enabled: true,
        country: { id: 263, title: 'Greece' },
      },
      entityLoaded: false,
      specialtyOptions: [],
      countryOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'sponsors',
        endpoint: 'sponsors',
        route: 'sponsors',
        title: {
          single: this.$t('Sponsor Add'),
          plural: this.$t('Sponsors'),
        },
      }
    },

    infoFormFields() {
      return [
        {
          id: 'title',
          type: 'text',
          label: this.$i18n.t('Company'),
          required: true,
          colSize: 6,
        },
        {
          id: 'businessSegment',
          type: 'text',
          label: this.$i18n.t('Business Segment'),
          required: true,
          colSize: 6,
        },
        {
          id: 'phone1',
          type: 'text',
          label: this.$i18n.t('phone1'),
          rules: 'phone',
          required: true,
          colSize: 6,
        },
        {
          id: 'email',
          type: 'email',
          label: this.$i18n.t('Email'),
          required: true,
          rules: 'email',
          colSize: 6,
        },
        {
          id: 'phone2',
          type: 'text',
          label: this.$i18n.t('phone2'),
          rules: 'phone',
          colSize: 3,
        },
        {
          id: 'phone3',
          type: 'text',
          label: this.$i18n.t('phone3'),
          rules: 'phone',
          colSize: 3,
        },
        {
          id: 'fax',
          type: 'text',
          label: this.$i18n.t('Fax'),
          rules: 'phone',
          colSize: 6,
        },
        {
          id: 'website',
          type: 'text',
          label: this.$t('website'),
          rules: 'url',
          colSize: 3,
        },
        {
          id: 'specialties',
          type: 'selectmultiple',
          object: true,
          label: this.$i18n.t('Specialties'),
          options: this.specialtyOptions,
          colSize: 9,
        },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('enabled'),
        },
      ]
    },
    contactFormFields() {
      return [
        {
          id: 'country',
          object: true,
          type: 'select',
          label: this.$t('country'),
          options: this.countryOptions,
          required: true,
          colSize: 6,
        },
        {
          id: 'city',
          type: 'text',
          label: this.$t('city'),
          required: true,
          colSize: 6,
        },
        {
          id: 'postalCode',
          type: 'text',
          label: this.$t('zip'),
          required: true,
          colSize: 4,
        },
        {
          id: 'address',
          type: 'text',
          label: this.$t('address'),
          required: true,
          colSize: 8,
        },
        {
          id: 'map',
          type: 'map',
          colSize: 12,
        },
      ]
    },
    billingFormFields() {
      return [
        {
          id: 'companyName',
          type: 'text',
          label: this.$i18n.t('Company Name'),
          colSize: 6,
        },
        {
          id: 'vatOffice',
          type: 'text',
          label: this.$i18n.t('vat office'),
          colSize: 6,
        },
        {
          id: 'vatId',
          type: 'number',
          label: this.$i18n.t('vat'),
          rules: 'positive',
          colSize: 6,
        },
        {
          id: 'vat',
          type: 'number',
          label: this.$i18n.t('tax'),
          rules: 'positive',
          colSize: 6,
        },
      ]
    },
    logoFormFields() {
      return [
        {
          id: 'logo',
          type: 'cropupload',
          label: `${this.$i18n.t('logo')} (512x512)`,
          colSize: 4,
          width: 512,
          height: 512,
          autoupload: true,
        },
        {
          id: 'description',
          type: 'quill',
          label: this.$i18n.t('description'),
          colSize: 8,
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchSpecialties').then(response => {
      this.specialtyOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCountries').then(response => {
      this.countryOptions = response.data
    })
  },
}
</script>
